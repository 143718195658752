import {sendGtagEvent} from "../utils/gtag";
import { openPopupById } from "../utils/togglePopup";
import {getValueFromBrowser} from "../utils/trackingQueryHook";

const footerAccordion = document.querySelector('.accordion.footer__info');
const sideMenu = document.querySelector('.sidemenu');

footerAccordion.addEventListener('click', () => {
    footerAccordion?.classList.toggle('collapsed');
});

const sidemenuAccorions = document.querySelectorAll('.sidemenu .accordion');

sidemenuAccorions.forEach((accordion) => {
    const accordionHeader = accordion.querySelector(".accordion__header");
    const dropdownCaret = accordionHeader.querySelector(".dropdown-caret");

    accordionHeader.addEventListener("click", (event) => {
        if (dropdownCaret.contains(event.target) || event.currentTarget.parentElement.id === 'exceptional-item') {
            if (!sideMenu?.classList.contains('is__open')) return;
            accordion.classList.toggle("open");
        } else {
            const href = accordionHeader.getAttribute("data-href");
            const isMissionsItem = href?.endsWith('missions');
            if (href) {
                const gtagEvent = accordionHeader.getAttribute("data-gtag");
                if (gtagEvent) {
                    sendGtagEvent(gtagEvent, {
                        affiliate_id: getValueFromBrowser('affiliateId') || '',
                    });
                }
                if (isMissionsItem) {
                    openPopupById("SignUp");
                } else {
                    window.location.href = href;
                }
            }
        }
    });
});
